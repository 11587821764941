import React from "react";
import "./ezugi.css";
import Helper from "../../helper";
import axios from 'axios';
import Appconfig from '../../config/config'
import { useNavigate } from 'react-router-dom';
import Header from "../header/Header";
import ExposureHeader from '../ExposureHeader/ExposureHeader';
import Footer from "../Footer/Footer";


const EzugiLobby = () => {
  const userInfo = Helper(); // get login user details
  const navigate = useNavigate();

  function CreateAndLaunchWCOCasino(provider_code, game_code, type = null) {
    const userData = {
      user_id: userInfo?._id,
      user_name: userInfo?.user_name,
      redirect_url: 'https://daddyexch.live/',
      provider_code: provider_code,
      game_code: game_code,
      type: type,
    };

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}wco/login`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(userData),
    };

    axios(config)
      .then(function (response) {
        console.log('response', response);
        if (response.data.status?.code === "SUCCESS") {
          window.open(response.data.launchURL, "_self");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function OpenEzugiLobby() {
    navigate();
  }

  return (
    <>
     <Header />
     <ExposureHeader />
      <div className="sub-new-nav">
        <div className="inner-sub-nav">
          <ul className="sub-nav-ul" id="slot-nav">
            <li className="slot-games-li">
              <button className="li-button" data-target="all-casino">
                <span>All</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="mac88-casino">
                <span>MAC88</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="fun-games-casino">
                <span>Fun Games</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="crash-games-casino">
                <span>Crash Games</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="turbo-games-casino">
                <span>Turbo Games</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="mac88-virtuals-casino">
                <span>Mac88 Virtuals</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="ezugi-casino">
                <span>Ezugi</span>
              </button>
            </li>
            <li className="slot-games-li" id="spribe">
              <button className="li-button" data-target="spribe-casino">
                <span>Spribe</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="jili-casino">
                <span>JiLi</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="smart-soft-casino">
                <span>Smart Soft</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="kingmaker-casino">
                <span>KINGMAKER</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="betgames-tv-casino">
                <span>BetGames.TV</span>
              </button>
            </li>
            <li className="slot-games-li">
              <button className="li-button" data-target="evolution-gaming-casino">
                <span>Evolution Gaming</span>
              </button>
            </li>

          </ul>
        </div>
      </div>

      <div class="image-section">
        <div class="all-casino active">
          <div class="page-live-casino">
            <ul class="casinos">
              <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`151027`)}><img src="https://img.jaipurapps.com/all-casino-img/avitor-crash/fungames-730_280.webp" alt="FUNGAMES" /></li>
              <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`150002`)} ><img src="https://img.jaipurapps.com/all-casino-img/baccarat/baccarat_mac88.webp" /></li>
              <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`150003`)}><img src="https://img.jaipurapps.com/all-casino-img/sicbo/sicbo_mac88.webp" alt="All" /></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links"   onClick={() => CreateAndLaunchWCOCasino(`DC`,`151005`)} ><img src="https://img.jaipurapps.com/all-casino-img/dragon-tiger/vr_2020_dtl.webp"  alt="All"/></li>
                <li class="casino-links"  onClick={() => CreateAndLaunchWCOCasino(`DC`,`151009`)}><img src="https://img.jaipurapps.com/all-casino-img/dragon-tiger/evo_emperor_golden_wealth_baccarat.webp"  alt="All"/></li>
                <li class="casino-links"  onClick={() => CreateAndLaunchWCOCasino(`DC`,`150036`)} ><img src= "https://img.jaipurapps.com/all-casino-img/dragon-tiger/dt_1day.webp" alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`900006`)} ><img src="https://img.jaipurapps.com/all-casino-img/dragon-tiger/vr_dragontiger.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`600005`)} ><img src= "https://img.jaipurapps.com/all-casino-img/dragon-tiger/dtl_20_20.webp" alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`EZ`,`150`)} ><img src="https://img.jaipurapps.com/all-casino-img/dragon-tiger/aes_dragon_tiger.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`PP`,`401`)}><img src="https://img.jaipurapps.com/all-casino-img/baccarat/bgt_baccarat.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`900086`)} ><img src="https://img.jaipurapps.com/all-casino-img/baccarat/bgt_baccarat.webp" alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`903002`)} ><img src="https://img.jaipurapps.com/all-casino-img/sicbo/sicbo-thumbnail.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`EZ`,`611007`)} ><img src="https://img.jaipurapps.com/all-casino-img/roulette/vip_roulette.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`220001`)} ><img src="https://img.jaipurapps.com/all-casino-img/video-slot/evp_vegasnights.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`EZ`,`507000`)} ><img src="https://img.jaipurapps.com/all-casino-img/baccarat/evo_2handcasinoholdem.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`QT`, `BTV-betonpoker`)} ><img src="https://img.jaipurapps.com/all-casino-img/poker/bgt_bet-on-poker.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`QT`, `BTV-lucky6`)}><img src="https://img.jaipurapps.com/all-casino-img/lucky-7/bgt_lucky6.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`QT`, `BTV-lucky5`)}><img src="https://img.jaipurapps.com/all-casino-img/lucky-7/bgt_lucky5.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`220010`)} ><img src="https://img.jaipurapps.com/all-casino-img/andarbahar/ott-ab-thumbnail.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`903006`)} ><img src="https://img.jaipurapps.com/all-casino-img/andarbahar/andar_bahar.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`600012`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/Jili_AK47.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links"  onClick={() => CreateAndLaunchWCOCasino(`DC`,`150010`)} ><img src="https://img.jaipurapps.com/all-casino-img/32cards/32_cards.webp"  alt="All"/></li>
                <li class="casino-links"  onClick={() => CreateAndLaunchWCOCasino(`DC`,`900034`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/sbc.webp"  alt="All"/></li>
                <li class="casino-links"  onClick={() => CreateAndLaunchWCOCasino(`DC`,`150047`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/kbc_vr.webp"  alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`600062`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/Jili_Ali_Baba.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`600030`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/Jili_Bubble_Beauty.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`400071`)} ><img src="https://img.jaipurapps.com/all-casino-img/andarbahar/bbl_andarbahar1.webp" alt="All"/></li>
            </ul>
            <ul class="casinos">
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`901013`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/vsbr_cricket1.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`SN`,`ARW`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/Akbar-Romeo-Walter_1654169275319.809.webp"  alt="All"/></li>
                <li class="casino-links" onClick={() => CreateAndLaunchWCOCasino(`DC`,`201243`)} ><img src="https://img.jaipurapps.com/all-casino-img/other/vsbr_tennis.webp"  alt="All"/></li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EzugiLobby;
