import React, { useState, useEffect } from 'react';
import { Link, NavLink, Navigate } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Button } from 'react-bootstrap';
import { FaDice, FaHorse, FaHorseHead, FaRegEye, FaRocket, FaTrophy, FaUser } from "react-icons/fa";
import { BiCricketBall, BiFootball, BiSolidTennisBall } from "react-icons/bi";
import { ImSpades } from 'react-icons/im';
import { PiMonitorPlay } from 'react-icons/pi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
// import "../../../src/App.css";
import LoginPopup from '../LoginPopup/LoginPopup';
import AccountStatus from '../AccountStatus/AccountStatus';
import { useNavigate } from 'react-router-dom';
import Helper from "../../helper";
import { useAuth } from "../AuthContext";
import Appconfig from '../../config/config'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { emptyUserInfo } from '../../redux/slice/userInfo/userInfoSlice';
import SignUp from '../SignUp/SignUp';
import Login from '../Login/Login';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { toast } from "react-toastify";



const Header = () => {
  const navigate = useNavigate();
  const userInfo = Helper();
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Right slider
  const [showMenu, setShowMenu] = useState(false); // Left slider
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showMyMarkets, setShowMyMarkets] = useState(false);
  const [showCricketList, setShowCricketList] = useState(false);
  const [showFootballList, setShowFootballList] = useState(false);
  const [showTennisList, setShowTennisList] = useState(false);
  const [showHorseRacingList, setShowHorseRacingList] = useState(false);
  const [showGrayHoundRacingList, setShowGrayHoundRacingList] = useState(false);
  const [showIndianCardGamesList, setShowIndianCardGamesList] = useState(false);
  const [showLoginModel, setShowLoginModel] = useState(false);
  const [showSignupModel, setShowSignupModel] = useState(false);
  const [showForgotModel, setShowForgotModel] = useState(false);
  const [whatsappNum, setWhatsAppNumber] = useState('');

  


  useEffect(() => {
    console.log("userInfo", userInfo);
  }, [userInfo]);



  const handleLogout = () => {
    handleClose();
    logout()
  };

  const handleToggleMenu = () => {
    if (showOffcanvas) {
      setShowOffcanvas(false); // Close the right slider if it's open
    }
    setShowMenu(!showMenu);
  };

  const handleUserClick = () => {
    if (showMenu) {
      setShowMenu(false); // Close the left slider if it's open
    }
    setShowOffcanvas(true);
  };

  const handleClose = () => {
    setShowOffcanvas(false);
  };

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  const handleDeposit = () => {
    setShowDeposit(true);
    navigate('/deposit');
  }
  const handleMyMarkets = () => {

    if (userInfo) {
      handleToggleMenu();
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }

  }

  const handleAviator = () => {
    if (userInfo) {
      CreateAndLaunchWCOCasino(`SPB`, `aviator`)
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }

  const handleCricketList = () => {
    if (userInfo) {
      handleToggleMenu();
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }
  const handleFootballList = () => {
    if (userInfo) {
      handleToggleMenu();
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }
  const handleTennisList = () => {
    if (userInfo) {
      handleToggleMenu();
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }
  const handleHorseRacingList = () => {
    if (userInfo) {
      CreateAndLaunchWCOCasino(`BT`)
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }
  const handleGrayHoundRacingList = () => {
    if (userInfo) {
      CreateAndLaunchWCOCasino(`BT`)
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }
  const handleIndianCardGamesList = () => {
    if (userInfo) {
      CreateAndLaunchWCOCasino(`BT`)
    } else {
      setShowMenu(false);
      setShowLoginModel(true);
    }
  }


  function CreateAndLaunchWCOCasino(provider_code, game_code, type = null) {
    const userData = {
      user_id: userInfo?._id,
      user_name: userInfo?.user_name,
      redirect_url: 'https://daddyexch.live/',
      provider_code: provider_code,
      game_code: game_code,
      type: type,
    };

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}wco/login`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(userData),
    };

    axios(config)
      .then(function (response) {
        console.log('response', response);
        let status = response.data.status?.code;
        if (status === "SUCCESS") {
          window.open(response.data.launchURL, "_self");
        }

        if (status == "VALIDATION_ERROR" || status == "UNKNOWN_ERROR") {
          toast.error('Game Not Found.', { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getWhatsAppNumber = () => {
    var data = JSON.stringify({
      "site_code": Appconfig.sitecodes,
    });
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}numbers/getNumberBySiteCode`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          if (response.data.result) {
            setWhatsAppNumber(response.data?.resultData?.mob_num)
          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        // postErrorToslack(error.message);
        console.log(error);
      });
  }

  const openWhatsApp = (whatsapp) => {
    const url = `https://wa.me/${whatsapp.replace(/\D/g, '')}`;
    window.open(url, "_blank");
  };


  const checkNewLogin = () => {
    var data = JSON.stringify({
      "login_token": userInfo.login_token,
      "user_id": userInfo._id
    });
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}userToken/getToken`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        try {
          if (response.data.result) {
          }
          else {
            localStorage.clear();
            localStorage.removeItem('userdata');
            dispatch(emptyUserInfo());
            localStorage.removeItem('login_token');
            window.location.href = "/";
          }
        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        // postErrorToslack(error.message);
        console.log(error);
      });
  }

  useEffect(() => {
    getWhatsAppNumber();
  }, [])

  // useEffect(() => {
  //   const chkLog = window.setInterval(function () {
  //     if (userInfo) {
  //       checkNewLogin();
  //     }
  //   }, 5000);
  //   return () => {
  //     clearInterval(chkLog)
  //   }
  // }, [])

  const togglePopup = (isClose = null) => {
    if (isClose == "Yes") {
      setShowLoginModel(false);
    } else {
      setShowLoginModel(!showLoginModel);
    }
    setShowForgotModel(false);
    setShowSignupModel(false);
    setShowMenu(false);
  };

  const toggleForgotPopup = () => {
    setShowLoginModel(false);
    setShowSignupModel(false);
    setShowForgotModel(!showForgotModel);
    setShowMenu(false);
  };

  const toggleSignUpPopup = () => {
    setShowLoginModel(false);
    setShowForgotModel(false);
    setShowSignupModel(!showSignupModel);
    setShowMenu(false);
  };
  return (
    <>
      <Login showPopup={showLoginModel} togglePopup={togglePopup} whatsappNum = {whatsappNum} openWhatsApp={openWhatsApp} toggleForgotPopup={toggleForgotPopup} toggleSignUpPopup={toggleSignUpPopup} handleClose={handleClose} />
      <ForgotPassword showForgotPopup={showForgotModel} togglePopup={togglePopup} toggleForgotPopup={toggleForgotPopup} handleClose={handleClose} />
      <SignUp showSignupPopup={showSignupModel} togglePopup={togglePopup} whatsappNum = {whatsappNum} openWhatsApp={openWhatsApp} toggleSignUpPopup={toggleSignUpPopup} handleClose={handleClose} />

      <Navbar variant="dark" expand="lg" fixed="top" className="sticky-header">
        <div className="d-flex align-items-center">
          {/* Hamburger Menu Button on the Left Side */}
          <Button
            className={`navbar-toggler me-2 hamburger ${showMenu ? 'open' : ''}`}
            type="button"
            onClick={handleToggleMenu}
            aria-controls="navbarSupportedContent"
            aria-expanded={showMenu}
            aria-label="Toggle navigation"
            variant="dark">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </Button>
          <Link className="navbar-brand" to="/"><img className='header-logo' src="/Images/logo.png" alt='' /></Link>
          <div className="user-section">
            {
              userInfo ?
                <>
                  <button className="button-30" role="button" onClick={handleDeposit}>Deposit</button>
                  <button onClick={handleUserClick} className='user-button'>
                    {
                      userInfo ?
                        <span className='user-name' title={userInfo ? userInfo?.user_name : ''}>{userInfo ? userInfo?.user_name : ''}</span>
                        :
                        ''
                    }
                    <FaUser size={15} color="white" style={{ marginLeft: 1, marginRight: 1, marginBottom: 5 }} className='fa-user' />
                  </button>
                </>
                :
                <>
                  <button type='button' className="login-btn-header" onClick={() => setShowLoginModel(true)}>
                    <span>Login</span>
                  </button>
                  <button type='button' className="signUp-btn-header" onClick={() => setShowSignupModel(true)}>
                    <span>Sign Up</span>
                  </button>
                  {
                    userInfo &&
                    <button onClick={handleUserClick} className='user-button'>
                      <FaUser size={15} color="white" style={{ marginLeft: 1, marginRight: 1, marginBottom: 5 }} className='fa-user' />
                    </button>
                  }
                </>
            }
          </div>
          {/* <div className="user-section">
            {
              userInfo &&
              <button className="button-30" role="button" onClick={handleDeposit}>Deposit</button>
            }
            <button onClick={handleUserClick} className='user-button'>
              {
                userInfo ?
                  <span className='user-name' title={userInfo ? userInfo?.user_name : ''}>{userInfo ? userInfo?.user_name : ''}</span>
                  :
                  ''
              }
              <FaUser size={15} color="white" style={{ marginLeft: 1, marginRight: 1, marginBottom: 5 }} className='fa-user' />
            </button>
          </div> */}
        </div>
      </Navbar>

      {/* Offcanvas menu for mobile (Left Slider) */}
      <Offcanvas show={showMenu} onHide={handleToggleMenu} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <button className="nav-link" onClick={handleMyMarkets}><FaRegEye size={30} color="black" style={{ marginRight: 10 }} />My markets</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleAviator}><FaRocket size={30} color="Orange" style={{ marginRight: 10 }} />Aviator</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleCricketList}><BiCricketBall size={30} color="#900C3F" style={{ marginRight: 10 }} />Cricket</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleFootballList}><BiFootball size={30} color="black" style={{ marginRight: 10 }} />Football</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleTennisList}><BiSolidTennisBall size={30} color="green" style={{ marginRight: 10 }} />Tennis</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleHorseRacingList}><FaHorseHead size={30} color="black" style={{ marginRight: 10 }} />Horce Racing</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleGrayHoundRacingList}><FaHorse size={30} color="brown" style={{ marginRight: 10 }} />Grayhound Racing</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleIndianCardGamesList}><ImSpades size={30} color="black" style={{ marginRight: 10 }} />Indian Card Games</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleIndianCardGamesList}><FaTrophy size={30} color="#ffd133" style={{ marginRight: 10 }} />Sportsbook (80+)</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleIndianCardGamesList}><PiMonitorPlay size={30} color="black" style={{ marginRight: 10 }} />Casino</button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={handleIndianCardGamesList}><FaDice size={30} color="red" style={{ marginRight: 10 }} />Slot Games</button>
              </li>
            </ul>
          </Nav>
          {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
        </Offcanvas.Body>
      </Offcanvas>

      {/* Fullscreen Offcanvas for Account Status (Right Slider) */}
      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-fullscreen">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Account Status</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AccountStatus handleLogout={handleLogout} handleClose={handleClose} userInfo={userInfo} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
