import React, { useState, useEffect } from "react";
import "./ProfitAndLoss.css";
// import "../../../src/App.css";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import ExposureHeader from "../ExposureHeader/ExposureHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import Helper from "../../helper";
import moment from 'moment';
import { Table } from 'antd';

const ProfitAndLoss = () => {
  const navigate = useNavigate();
  const userInfo = Helper(); // get login user details

  const [filteredData, setFilteredData] = useState([]);

  const [profitandloss2, setProfitAndLoss2] = useState(false);
  const [formData, setFormData] = useState({
    "user_id": userInfo && userInfo?._id,
    "from_date": moment().subtract(1, 'days').format('YYYY-MM-DD'),
    "to_date": moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    filterProfitLoss();
  }, [])

  const filterProfitLoss = async (e) => {

    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/auraProfitLossByEventType`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        try {
          // console.log(response.data.resultData);
          setFilteredData(response.data.resultData);

        } catch (e) {
          // postErrorToslack(e.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        // postErrorToslack(error.message);
      });
  }

  const handleToDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        to_date: newDate
      })
    }
  };

  const handleFromDate = (newDate) => {
    if (newDate) {
      setFormData({
        ...formData,
        from_date: newDate
      })
    }
  };


  const columns = [
    {
      title: 'Sport Name',
      dataIndex: 'name',
      sorter: true,

      render: (_, record) => (
        record.event_type == 4 ?
          (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Cricket
            </Link>
          ) : record.event_type == 2 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Tennis
            </Link>
          ) : record.event_type == 1 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Soccer
            </Link>
          ) : record.event_type == 7 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Horse Racing
            </Link>
          ) : record.event_type == 1004 || record.event_type == 1005 || record.event_type == 1007 || record.event_type == 1008 || record.event_type == 1009 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Casino
            </Link>
          ) : record.event_type == 4339 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Greyhound Racing
            </Link>
          ) : record.event_type == 27979456 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Kabaddi
            </Link>
          ) : record.event_type == 7522 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Basketball
            </Link>
          ) : record.event_type == 2378961 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Politics
            </Link>
          ) : record.event_type == 66101 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Virtual
            </Link>
          ) : record.event_type == 66104 ? (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Lottery
            </Link>
          ) : (
            <Link className=' ' to={`/profit-loss-event/${record.event_type}/${formData.from_date}/${formData.to_date}`}>
              Other
            </Link>
          )
      ),
    },
    {
      title: 'Profit/Loss',
      dataIndex: 'name',
      sorter: true,
      render: (_, record) => (
        record.totalPL < 0 ? (
          <span className=' '>
            {record?.totalPL?.toFixed(2)}
          </span>
        ) : (
          <span className=' '>{
            record?.totalPL?.toFixed(2)
          }</span>
        )
      )
    },
    // {
    //   title: 'Commission',
    //   dataIndex: 'name',
    //   sorter: true,
    //   render: (_, record) => (
    //     <span className=''>{record?.commissionAmount?.toFixed(2)}</span>
    //   )
    // },
    {
      title: 'Total P&L',
      dataIndex: 'name',
      sorter: false,
      render: (_, record) => (
        record.totalPL < 0 ? (
          <span className=''>
            {(record?.totalPL + record?.commissionAmount).toFixed(2)}
          </span>
        ) : (
          <span className=' '>{
            (record?.totalPL + record?.commissionAmount).toFixed(2)
          }</span>
        )
      )
    },
  ];


  return (
    <>
      <Header />
      <ExposureHeader />
      <div className="account-statment">
        <p className="open-para">
          <span>Profit & Loss</span>
        </p>


        <div className="form" style={{ merge: '10px' }}>
          <form>
            <div className="date-time">
              <input type="date" name="from_date" onChange={(e) => handleFromDate(e.target.value)} value={formData?.from_date || new Date()} className="d-and-t" style={{ marginBottom: '0px' }} />
              <input type="date" name="to_date" onChange={(e) => handleToDate(e.target.value)} value={formData?.to_date || new Date()} className="d-and-t" style={{ marginBottom: '0px' }}/>
            </div>
            <div className="new-buttons">
              <input type="button" value={'submit'} onClick={() => { filterProfitLoss() }} className="btn-submit" />
              <input type="button" value={'reset'} className="btn-submit" />
            </div>
          </form>
        </div>
      </div>


      <div className="table-container">
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={true}
          bordered={true}
        />
      </div>

      <Footer />
    </>
  );
};

export default ProfitAndLoss;
