import React from 'react';
import './Main.css';
// import "../../../src/App.css";
import { Carousel } from 'react-bootstrap';

const Main = () => {
  return (
    <div>
      <Carousel>
        <Carousel.Item interval={8000}>
          <img src="./Images/banner1.webp" className="banner-images" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={8000}>
          <img src="./Images/banner2.webp" className="banner-images" alt="..." />
        </Carousel.Item>
        <Carousel.Item interval={8000}>
          <img src="./Images/banner3.webp" className="banner-images" alt="..." />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
export default Main;