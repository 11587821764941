import React, { useState, useEffect, useRef } from "react";
import "./Deposit.css";
// import "../../../src/App.css";
import "../Withdraw/Withdraw.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileUpload } from "react-icons/fa";
import Header from "../header/Header";
import Helper from "../../helper";
import Appconfig from '../../config/config'
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getPaymentMethods } from '../../redux/slice/paymentMethod/paymentMethodSlice';
import { useSelector, useDispatch } from 'react-redux';
import ExposureHeader from '../ExposureHeader/ExposureHeader';
import { Modal, Input, Table, Image, Button, Switch } from 'antd';



const Deposit = () => {
  const navigate = useNavigate();
  const userInfo = Helper();
  const dispatch = useDispatch();
  const userInfos = useSelector((state) => state.paymentMethods); // Use selector to access state
  const paymentMethod = userInfos?.paymentMethods || [];

  const [amountValue, setAmountValue] = useState("");
  const [utrValue, setUTRValue] = useState("");

  const [selectedOption, setSelectedOption] = useState("");
  const [methodDetail, setMethodDetail] = useState("");
  const [depositHistory, setDepositHistory] = useState([]);


  const [isSubmitted, setIsSubmitted] = useState(false); // State for form submission
  const [buttonText, setButtonText] = useState("Submit"); // State for button text
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);
  const [imageList, setImageList] = useState(null); // State to store the image list

  const [balance, setBalance] = useState(0)
  const [exposure, setExposure] = useState(0)
  let balanceWithExp = balance - Math.abs(exposure);

  const historyColumns = [
    {
      title: 'User Name',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {record?.user_name}
        </span>
      ),
    },
    // {
    //   title: 'User Id',
    //   sorter: true,
    //   render: (_, record) => (
    //     <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
    //       {record?.user_id}
    //     </span>
    //   ),
    // },
    {
      title: 'Type',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {record?.type}
        </span>
      ),
    },
    ...(depositHistory.some((record) => record.bank_name) // Check if any record has a bank name
      ? [
        {
          title: 'Bank Name',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.bank_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(depositHistory.some((record) => record.account_no) // Check if any record has a bank name
      ? [
        {
          title: 'Account Num',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.account_no || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(depositHistory.some((record) => record.account_holder_name) // Check if any record has a bank name
      ? [
        {
          title: 'Account Holder Name',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.account_holder_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(depositHistory.some((record) => record.ifsc_code) // Check if any record has a bank name
      ? [
        {
          title: 'IFSC Code',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.ifsc_code || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(depositHistory.some((record) => record.upi_id) // Check if any record has a bank name
      ? [
        {
          title: 'UPI Id',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.upi_id || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
    ...(depositHistory.some((record) => record.holder_name) // Check if any record has a bank name
      ? [
        {
          title: 'Holder Name',
          sorter: true,
          render: (_, record) => (
            <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
              {record?.holder_name || 'N/A'}
            </span>
          ),
        },
      ]
      : []), // If no record has a bank name, exclude this column
      {
        title: 'Remarks',
        sorter: true,
        render: (_, record) => (
          <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
            {record?.remark}
          </span>
        ),
      },
    {
      title: 'Amount',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {record?.amount}
        </span>
      ),
    },
    {
      title: 'Screen Shot',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {
            record?.screenshot_name == null ? "No Screenshot" : <Image
              width={70}
              src={`${Appconfig.apiUrl}deposit/${record?.screenshot_name}`}
            />
          }

        </span>
      ),
    },
    {
      title: 'UTR Number',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {
            record?.utr_number
          }
        </span>
      ),
    },
    {
      title: 'Request Date',
      sorter: true,
      render: (_, record) => (
        <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>
          {(new Date(record?.createdAt)).toLocaleString()}
        </span>
      ),
    },
    {
      title: 'Status',
      sorter: true,
      render: (_, record) => (
        <span className={`font-extrabold text-[#212529] text-[0.813rem] ml-[5px] ${record?.status == 'Reject' ? 'text-[red]' : 'text-[green]'}`}>
          {record?.status}
        </span>
      ),
    },
  ];


  const getDepositHistory = () => {

    let site_code = Appconfig.sitecodes;
    let data = JSON.stringify({
      site_code: site_code,
      user_id: userInfo?._id
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}deposit/getDepositHistory`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          setDepositHistory(response.data.resultData);
        } else {
          toast.error(response.data.resultMessage, { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  useEffect(() => { getDepositHistory() }, []);

  // Function to handle button click
  const handleButtonClick = (value) => {
    setAmountValue(value);
  };

  // Function to handle payment option click
  const handleClick = (option, detail) => {
    setSelectedOption(option);
    setMethodDetail(detail);
  };

  // Button values
  const buttonValues = [500, 1000, 1500, 2000, 2500, 3000];

  const listRef = useRef(null); // Ref to target the scrolling list

  const scrollLeft = () => {
    listRef.current.scrollBy({
      left: -300, // Scroll left by 150px
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    listRef.current.scrollBy({
      left: 300, // Scroll right by 150px
      behavior: "smooth",
    });
  };


  useEffect(() => {
    if (userInfo) {
      getBalance();
    }
  }, [userInfo])

  function getBalance() {
    var data = JSON.stringify({
      user_id: userInfo?._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/getUserBalance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 0) {
        } else {
          setBalance(response.data.resultData?.balance)
          setExposure(response.data.resultData?.exposure)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    dispatch(getPaymentMethods({
      site_code: Appconfig.sitecodes,
      user_id: Appconfig.superAdmin
    }));
  }, [])


  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX); // Get the starting X position of the touch
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX); // Get the X position during the swipe
  };

  // Handle touch end
  const handleTouchEnd = () => {
    const touchDiff = touchStartX - touchEndX;

    if (touchDiff > 50) {
      // Swipe left (scroll right)
      scrollRight();
    } else if (touchDiff < -50) {
      // Swipe right (scroll left)
      scrollLeft();
    }
  };

  const handleImageUpload = (e) => {
    // console.log('e', e.target.files[0]);
    setImageList(e.target.files[0]);
  }

  // Handle submit click and toggle button text & style
  const handleSubmitClick = async (e) => {
    e.preventDefault();

    if (userInfo && selectedOption && amountValue) {

      const formData = new FormData();

      formData.append("master_id", userInfo?.master_id);
      formData.append("user_id", userInfo?._id);
      formData.append("site_code", userInfo?.site_code);
      formData.append("user_name", userInfo?.user_name);
      formData.append("type", selectedOption);
      formData.append("bank_name", methodDetail?.bankName);
      formData.append("account_no", methodDetail?.accountNo);
      formData.append("account_holder_name", methodDetail?.accountHolder);
      formData.append("ifsc_code", methodDetail?.ifscCode);
      formData.append("holder_name", methodDetail?.holderName);
      formData.append("upi_id", methodDetail?.upiId);
      formData.append("amount", amountValue);
      formData.append("utr_number", utrValue);
      formData.append("file", imageList); // Include the uploaded file

      var config = {
        method: 'post',
        url: `${Appconfig.apiUrl}deposit/addDepositRequest`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      };
      axios(config)
        .then(function (response) {
          if (response.data.result) {
            setButtonText("Thanks");
            setIsSubmitted(true);
            toast.success(response.data.resultMessage, { autoClose: 2000 });

            setTimeout(() => {
              navigate("/");
            }, 2000);

          } else {
            toast.error(response.data.resultMessage, { autoClose: 2000 });
          }

        })
        .catch(function (error) {
          console.log(error);
        });

    } else {
      toast.error("Please fill all the fields!", { autoClose: 2000 });
    }

  };

  return (
    <>
      <Header />
      <ExposureHeader />
      <div className="deposit-withdraw">
        <p className="deposit">
          <span>Deposit</span>
        </p>

        <div className="payments-symbol">
          <h3 className="payments-options">Payment Options</h3>

          <div className="scroll-container">
            <ul className="payments">
              {/* Update the list items to call handleClick with the respective option */}

              {
                paymentMethod.length > 0 &&
                paymentMethod?.map((item, index) => {
                  return (
                    <div key={index}>
                      {
                        item.type == "Bank" && (
                          <li className={`click ${selectedOption == "Bank" ? "activated" : ""}`} onClick={() => handleClick("Bank", item)}>
                            <img src="/Images/bank-transfer.png" alt="Bank" className="upi" />
                          </li>
                        )
                      }
                      {
                        item.type == "UPI" && (
                          <li className={`click ${selectedOption == "UPI" ? "activated" : ""}`} onClick={() => handleClick("UPI", item)}>
                            <img src="/Images/UPI-Color.webp" alt="UPI" className="upi" />
                          </li>
                        )
                      }
                      {
                        item.type == "Gpay" && (
                          <li className={`click ${selectedOption == "Gpay" ? "activated" : ""}`} onClick={() => handleClick("Gpay", item)}>
                            <img src="/Images/G-pay.webp" alt="Gpay" className="upi" />
                          </li>
                        )
                      }
                      {
                        item.type == "Phonepe" && (
                          <li className={`click ${selectedOption == "Phonepe" ? "activated" : ""}`} onClick={() => handleClick("Phonepe", item)}>
                            <img
                              src="/Images/phonepe.webp"
                              alt="PhonePe"
                              className="upi"
                            />
                          </li>
                        )
                      }
                      {
                        item.type == "Paytm" && (
                          <li className={`click ${selectedOption == "Paytm" ? "activated" : ""}`} onClick={() => handleClick("Paytm", item)}>
                            <img src="Images/paytm.webp" alt="Paytm" className="upi" />
                          </li>
                        )
                      }
                      {/* 
                      <li className="click" onClick={() => handleClick("paypal")}>
                        <img src="../Images/paypal.png" alt="Paypal" className="upi" />
                      </li>
                      <li className="click" onClick={() => handleClick("applepay")}>
                        <img
                          src="../Images/apple-pay.png"
                          alt="Applepay"
                          className="upi"
                        />
                      </li> */}
                    </div>
                  )
                })
              }

            </ul>
          </div>

          <p className="see-more">
            1. Deposit money only in the below available accounts to get the
            fastest credit and avoid possible delays.
          </p>
        </div>

        {/* Conditionally render the divs based on the selected option */}
        <div className="payment-details">
          {selectedOption === "Bank" && (
            <div className="details">
              <h4>Current Available Balance : &#8377; {balance && Number(balanceWithExp).toFixed(2)}</h4>
              <h3>Payments Details</h3>
              <div className="account">
                <p>Account Number</p>
                <span>{methodDetail?.accountNo}</span>
              </div>
              <div className="account">
                <p>IFSC Code</p>
                <span>{methodDetail?.ifscCode}</span>
              </div>
              <div className="account">
                <p>Account Holder Name</p>
                <span>{methodDetail?.accountHolder}</span>
              </div>
              <div className="account">
                <p>Bank Name</p>
                <span>{methodDetail?.bankName}</span>
              </div>
            </div>
          )}


          {(selectedOption === "Gpay" || selectedOption === "Phonepe" || selectedOption === "UPI" || selectedOption === "Paytm") && (
            <div className="details">
              <h4>Current Available Balance : &#8377; {balance && Number(balanceWithExp).toFixed(2)}</h4>
              <h3>Payments Details</h3>
              <div className="account">
                <p>UPI Id</p>
                <span>{methodDetail?.upiId}</span>
              </div>
              <div className="account">
                <p>Holder Name</p>
                <span>{methodDetail?.holderName}</span>
              </div>
            </div>
          )}


          <form className="details" onSubmit={handleSubmitClick} >
            <div className="input-container">
              <h3>
                Amount<span className="red">*</span>
              </h3>
              <input
                type="number"
                value={amountValue}
                onChange={(e) => setAmountValue(e.target.value)}
                placeholder="&#8377;Enter amount"
                className="amount-input"
              />
            </div>

            <div className="button-container">
              {buttonValues.map((value, index) => (
                <button
                  key={index}
                  type="button"
                  className="amount-button"
                  onClick={() => handleButtonClick(value)}
                >
                  {value}
                </button>
              ))}
            </div>
            <div className="payment-slip">
              <h3>
                Upload your payment slip below <span className="red">*</span>
              </h3>
              <div className="file-upload">
                <label className="file-upload-label">
                  <input type="file" className="file-input" onChange={(e) => handleImageUpload(e)} />
                  <FaFileUpload className="file-icon" />
                  <span className="file-upload-text">  {imageList ? imageList.name : "Upload File"}
                  </span>
                </label>
              </div>
              <div className="unique-transaction">
                <h3>
                  Unique Transaction Reference <span className="red">*</span>
                </h3>
                <input
                  className="unique"
                  type="text"
                  value={utrValue}
                  onChange={(e) => setUTRValue(e.target.value)}
                  placeholder="6 to 23 Digit UTR RRN Number"
                />
              </div>
            </div>
            <div className="agree">
              <p>
                <input type="checkbox" />{" "}
                <span className="terms-conditions">
                  I have read the term and condition
                </span>{" "}
                <a className="terms-conditions-link" href="#">
                  the terms of payments and deposit policy
                </a>
              </p>
            </div>

            <div className="section-submit-button">
              <button
                id="btn"
                type="submit"
                disabled={isSubmitted ? true : false}
                className={`payment-submit-button ${isSubmitted ? "activated" : ""
                  }`}
              >
                <p className="submit-button-text">{buttonText}</p>
                <div className="checked">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                    <path
                      fill="transparent"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    ></path>
                  </svg>
                </div>
              </button>
            </div>
          </form>

        </div>
      </div >

      <div style={{ padding: "10px" }}>
        <div>
          <h3 style={{ color: 'black' }}>Deposit History</h3>
        </div>

        <div className="deposit-history" style={{ overflowX: 'auto' }}>
          <Table
            columns={historyColumns}
            className='downline-list'
            bordered
            dataSource={depositHistory}
            pagination={true}
          />
        </div>
      </div>

    </>
  );
};

export default Deposit;
