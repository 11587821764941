import React from "react";
import "./ScrollCasino.css";
import Helper from "../../helper";
import axios from 'axios';
import Appconfig from '../../config/config'
import { useNavigate } from 'react-router-dom';


const ScrollCasino = () => {
  const userInfo = Helper(); // get login user details
  const navigate = useNavigate();

  function CreateAndLaunchWCOCasino(provider_code, game_code, type = null) {
    const userData = {
      user_id: userInfo?._id,
      user_name: userInfo?.user_name,
      redirect_url: 'https://daddyexch.live/',
      provider_code: provider_code,
      game_code: game_code,
      type: type,
    };

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}wco/login`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(userData),
    };

    axios(config)
      .then(function (response) {
        console.log('response', response);
        if (response.data.status?.code === "SUCCESS") {
          window.open(response.data.launchURL, "_self");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function OpenEzugiLobby(){
    navigate('/ezugiLobby');
  }

  return (
    <>
      <div className="live-casino">
        <div className="live-casino-heading">
          <p>Popular Games</p>
        </div>
        <div className="scrollable-box">
          <div className="inner-box scroll-casino" style={{ marginBottom: '7px' }}>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`151027`)} className="image-setting">
              <img src="../Images/Popular_games/Super_Over.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`150020`)} className="image-setting">
              <img src="../Images/Popular_games/3_Cards_Judgement.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`600113`)} className="image-setting">
              <img src="../Images/Popular_games/Live_Teenpatti..webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino('','','EV')} className="image-setting">
              <img src="../Images/Popular_games/Teenpatti_T20.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`600029`)} className="image-setting">
              <img src="../Images/Popular_games/Teenpatti_Test.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`QT`,`1x2-plinkogo`)} className="image-setting">
              <img src="../Images/Popular_games/2_Cards_Teenpatti.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`150`)} className="image-setting">
              <img src="../Images/Popular_games/Teenpatti_Open.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`228001`)} className="image-setting">
              <img src="../Images/Popular_games/Muflis_Teenpatti.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`228002`)} className="image-setting">
              <img src="../Images/Popular_games/dragon_tiger.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`600113`)} className="image-setting">
              <img src="../Images/Popular_games/32_cards_casino.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`5001`)} className="image-setting">
              <img src="../Images/Popular_games/Bollywood_Casino.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`321000`)} className="image-setting">
              <img src="../Images/Popular_games/Amar_Akbar_Anthony.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino('EVZ','1000013','EV')} className="image-setting">
              <img src="../Images/Popular_games/queen.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`180`)} className="image-setting">
              <img src="../Images/Popular_games/Baccarat.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`481000`)} className="image-setting">
              <img src="../Images/Popular_games/29_Card_Baccarat.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`221009`)} className="image-setting">
              <img src="../Images/Popular_games/Casino_War.webp" alt="" />
            </button>
          </div>
          <div className="inner-box scroll-casino" style={{ marginBottom: '7px' }}>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`611003`)} className="image-setting">
              <img src="../Images/Popular_games/Casino_Meter.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`221003`)} className="image-setting">
              <img src="../Images/Popular_games/Trio.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`507000`)} className="image-setting">
              <img src="../Images/Popular_games/Hi_Low.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`275`)} className="image-setting">
              <img src="../Images/Popular_games/Race_20_20.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`51`)} className="image-setting">
              <img src="../Images/Popular_games/Race_to_17.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)} className="image-setting">
              <img src="../Images/Popular_games/7_up_Down.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`541001`)} className="image-setting">
              <img src="../Images/Popular_games/Andar_Bahar.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino('EVZ','1000013','EV')} className="image-setting">
              <img src="../Images/Popular_games/Poker.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`227100`)} className="image-setting">
              <img src="../Images/Popular_games/Poker_20_20.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`224000`)} className="image-setting">
              <img src="../Images/Popular_games/Six_player_poker.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)} className="image-setting">
              <img src="../Images/Popular_games/The_Trap.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`224000`)} className="image-setting">
              <img src="../Images/Popular_games/Worli_Matka.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`DC`,`600144`)} className="image-setting">
              <img src="../Images/Popular_games/Roulette.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`, `321000`)} className="image-setting">
              <img src="../Images/Popular_games/Sicbo.webp" alt="" />
            </button>
            <button onClick={() => CreateAndLaunchWCOCasino(`EZ`,`228000`)} className="image-setting">
              <img src="../Images/Popular_games/dream_catcher.webp" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="casino-background">
        <div className="live-casino">
          <div className="live-casino-heading">
            <p>Casino Provider</p>
          </div>
          <div className="scrollable-box">
            {/* <div className="inner-box scroll-casino" style={{ marginBottom: '7px' }}>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/evolution.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/ezugi.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/jili.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/gamzix.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/kingmaker.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/smartsoft.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/spribe.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/turbogames.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/vivogaming.webp" alt="" />
              </button>
            </div> */}
            <div className="casino-provider-layout">
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`SPB`, `aviator`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/evolution.webp" alt="" />
                </li>
                <li onClick={() => OpenEzugiLobby()} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/ezugi.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `600113`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/jili.webp" alt="" />
                </li>
              </ul>
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`SPB`, `mines`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/gamzix.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/kingmaker.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`SPB`, `mines`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/smartsoft.webp" alt="" />
                </li>
              </ul>
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/mac88virtualgame.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/playtech.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/mac88.webp" alt="" />
                </li>
              </ul>
            </div>
            <div className="casino-provider-layout">
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`EZ`, `321000`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/vsport.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`EZ`, `321000`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/evoplay.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`EZ`, `321000`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/fungames_500-299.webp" alt="" />
                </li>
              </ul>
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `600087`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/bombaylive.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `600011`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/betgames.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/aesexy.webp" alt="" />
                </li>
              </ul>
              <ul>
                <li onClick={() => CreateAndLaunchWCOCasino(`DC`, `600011`)} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/spribe.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/turbogames.webp" alt="" />
                </li>
                <li onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                  <img src="../Images/Casino_providers/vivogaming.webp" alt="" />
                </li>
              </ul>
            </div>
            {/* <div className="inner-box scroll-casino" style={{ marginBottom: '7px' }}>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/mac88virtualgame.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/playtech.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/mac88.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/vsport.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/evoplay.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/fungames_500-299.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/bombaylive.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/betgames.webp" alt="" />
              </button>
              <button onClick={() => CreateAndLaunchWCOCasino()} className="image-setting-live-casino">
                <img src="../../../Images/Casino_providers/aesexy.webp" alt="" />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollCasino;
