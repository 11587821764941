import React, { useState } from 'react';
import './GamesImages.css';
// import "../../../src/App.css";
import Helper from "../../helper";
import axios from 'axios';
import Appconfig from '../../config/config'
import { toast } from "react-toastify";
import LoginPopup from '../LoginPopup/LoginPopup';


const GamesImages = () => {

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const userInfo = Helper(); // get login user details

  const handleLoginPopupClick = () => {
    setShowLoginPopup(true); // Show the login popup
  };

  const handleLoginPopupClose = () => {
    setShowLoginPopup(false); // Hide the login popup
  };

  function CreateAndLaunchWCOCasino(provider_code, game_code, type = null) {
    const userData = {
      user_id: userInfo?._id,
      user_name: userInfo?.user_name,
      redirect_url: 'https://daddyexch.live/',
      provider_code: provider_code,
      game_code: game_code,
      type: type,
    };

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}wco/login`,
      headers: {
        "Content-Type": "application/json",
      },

      data: JSON.stringify(userData),
    };

    axios(config)
      .then(function (response) {
        console.log('response', response);
        let status = response.data.status?.code;
        if (status === "SUCCESS") {
          window.open(response.data.launchURL, "_self");
        }

        if (status == "VALIDATION_ERROR" || status == "UNKNOWN_ERROR") {
          toast.error('Game Not Found.', { autoClose: 2000 });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div className="scrollable-box-image">
        <div className="inner-box-image">
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`SPB`, `aviator`)}>
            <img className='box-image' src="../Images/Top_images/44107-1-1734277651.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`DC`, `600113`)}>
            <img className='box-image' src="../Images/Top_images/17398-1-1736587583.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`SPB`, `mines`)}>
            <img className='box-image' src="../Images/Top_images/49746-1-1733297233.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`DC`, `151084`)}>
            <img className='box-image' src="../Images/Top_images/92029-1-1737182561.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`EZ`, `321000`)}>
            <img className='box-image' src="../Images/Top_images/98788.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`EZ`, `150`)}>
            <img className='box-image' src="../Images/Top_images/ezg_dragontiger.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`DC`, `600087`)}>
            <img className='box-image' src="../Images/Top_images/jili_dragon_&_tiger.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`DC`, `600011`)}>
            <img className='box-image' src="../Images/Top_images/jili_teenpatti.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`EZ`, `601000`)}>
            <img className='box-image' src="../Images/Top_images/MX-LIVE-009.webp" alt="" />
          </button>
          <button className='box-images' onClick={() => CreateAndLaunchWCOCasino(`DC`, `150009`)}>
            <img className='box-image' src="../Images/Top_images/RG-1TP101.webp" alt="" />
          </button>
        </div>
        {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
      </div>
    </div>
  );
}

export default GamesImages;
